<template>
  <div>
    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <h2><span class="text-muted">Account Settings / </span><span>Billing &amp; Plans</span></h2>
      </b-col>
    </b-row>

    <b-overlay
      variant="transparent"
      :show="loadingOverlay"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <b-spinner
            type="grow"
            variant="dark"
          />
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <!-- We add an SR only text for screen readers -->
          <span class="sr-only">Please wait...</span>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col cols="12">
          <b-nav pills>
            <b-nav-item :to="{ name: 'settings-profile' }">
              <font-awesome-icon
                :icon="['far', 'user']"
                size="xl"
              />
              Account
            </b-nav-item>
            <b-nav-item active>
              <font-awesome-icon
                :icon="['far', 'file-invoice']"
                size="xl"
              />
              Billing &amp; Plans
            </b-nav-item>
            <b-nav-item :to="{ name: 'settings-users' }">
              <font-awesome-icon
                :icon="['far', 'users']"
                size="xl"
              />
              Invite Users
            </b-nav-item>
          </b-nav>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-card>
            <h3>Current Plan</h3>
            <b-row v-if="billingData">
              <b-col md="6">
                <p class="pt-1">
                  Your Current Plan is <span>{{ billingData.name }}</span><br>
                  <small class="text-muted">A simple start for everyone</small>
                </p>
                <p class="pt-1">
                  Next billing date: {{ getReadableDateOnly(billingData.next_billing_date) }}<br>
                  <small class="text-muted">Your invoice will be available in billing history</small>
                </p>
                <p class="pt-1">
                  $<span v-if="billingData.price_dollars">{{ billingData.price_dollars }}</span> Per Month &nbsp; <span class="badge badge-primary">Popular</span><br>
                  <small class="text-muted">Standard plan for small to medium businesses</small>
                </p>
              </b-col>
              <b-col md="6">
                <b-alert
                  v-if="invoiceToDate
                    && invoiceToDate.line_items
                    && invoiceToDate.line_items.google_ads_spend
                    && invoiceToDate.line_items.google_ads_spend.cost > 500
                    && invoiceToDate.line_items.forms.count > 250"
                  show
                  variant="warning"
                  class="p-2"
                >
                  <p class="font-weight-bold mb-1">
                    We need your attention!
                  </p>
                  <p>
                    Your plan requires an update.
                  </p>
                </b-alert>
                <b-row class="mt-1">
                  <b-col cols="6">
                    Ad spend to date
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    <!-- {{ currentDayOfMonth }} of {{ totalDaysInMonth }} Days -->
                  </b-col>
                  <b-col cols="12">
                    <b-progress
                      :max="planCount.adSpendMax"
                      height="1.2rem"
                      show-value
                    >
                      <b-progress-bar
                        :value="planCount.adSpend"
                        variant="success"
                      >
                        <span class="progressbar-label">${{ planCount.adSpend }}</span>
                      </b-progress-bar>
                    </b-progress>
                    <!-- <b-form-input
                      type="range"
                      min="1"
                      :max="totalDaysInMonth"
                      :value="currentDayOfMonth"
                      step="1"
                      class="form-control-range"
                      disabled
                    /> -->
                    <small
                      v-if="planCount"
                      class="text-muted"
                    >
                      ${{ planCount.adSpend }} ad spend to date
                    </small>
                  </b-col>
                </b-row>
                <b-row class="mt-1">
                  <b-col cols="6">
                    Form submissions to date
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    <!-- {{ currentDayOfMonth }} of {{ totalDaysInMonth }} Days -->
                  </b-col>
                  <b-col cols="12">
                    <b-progress
                      :max="planCount.formsMax"
                      height="1.2rem"
                      show-value
                    >
                      <b-progress-bar
                        v-b-popover.hover.top="planCount.formsLabelText"
                        :value="planCount.forms"
                        :variant="planCount.formsLabel"
                      >
                        <span class="progressbar-label">{{ planCount.forms }}</span>
                      </b-progress-bar>
                      <b-progress-bar
                        v-b-popover.hover.top="planCount.formsOverageLabelText"
                        :value="planCount.formsOverage"
                        variant="danger"
                        striped
                      >
                        <span class="progressbar-label">{{ planCount.formsOverage }}</span>
                      </b-progress-bar>
                    </b-progress>
                    <!-- <b-form-input
                      type="range"
                      min="1"
                      :max="totalDaysInMonth"
                      :value="currentDayOfMonth"
                      step="1"
                      class="form-control-range"
                      disabled
                    /> -->
                    <small
                      v-if="invoiceToDate.line_items && invoiceToDate.line_items.forms"
                      class="text-muted"
                    >
                      {{ invoiceToDate.line_items.forms.count || 0 }} of {{ billingData.forms_per_month }} form submissions to date
                    </small>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <p>
              <b-button
                variant="primary"
                type="button"
              >
                Save Changes
              </b-button>&nbsp;<b-button
                disabled
                variant="secondary"
                type="button"
              >
                Cancel
              </b-button>
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="">
        <b-col cols="12">
          <b-card>
            <h3>Payment Methods</h3>
            <b-row>
              <b-col md="6">
                <p class="pt-1">
                  <b-form inline>
                    <b-form-radio
                      v-model="paymentMethod"
                      name="paymentMethod"
                      value="card"
                      class="custom-control-primary"
                    >
                      Credit/Debit/ATM Card
                    </b-form-radio>
                  </b-form>
                </p>
                <div class="pt-1">
                  <b-form>
                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          label="Card Number"
                          label-for="card-number"
                        >
                          <b-form-input
                            id="card-number"
                            v-model="tempCreditCard.number"
                            placeholder="7563 4592 4863 9328"
                            :formatter="formatCreditCardNumber"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group
                          label="Name"
                          label-for="name"
                        >
                          <b-form-input
                            id="name"
                            v-model="tempCreditCard.name"
                            placeholder="John Doe"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <b-form-group
                          label="EXP. Date"
                          label-for="exp"
                        >
                          <b-form-input
                            id="exp"
                            v-model="tempCreditCard.exp"
                            placeholder="MM/YY"
                            :formatter="formatExpiryDate"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <b-form-group
                          label="CVV"
                          label-for="cvv"
                        >
                          <b-form-input
                            id="cvv"
                            v-model="tempCreditCard.cvv"
                            placeholder="455"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <p class="pt-1">
                      <b-button
                        variant="primary"
                        type="button"
                        @click="setCreditCard"
                      >
                        Save Changes
                      </b-button>&nbsp;<b-button
                        disabled
                        variant="secondary"
                        type="button"
                      >
                        Cancel
                      </b-button>
                    </p>
                  </b-form>
                </div>
              </b-col>
              <b-col md="6">
                <p>My Card</p>
                <b-row>
                  <b-col cols="12">
                    <b-card
                      v-if="creditCard.last_four"
                      class="border"
                    >
                      <b-row>
                        <b-col md="6">
                          <h3><i
                            class="fa-brands display-4"
                            :class="cardType[creditCard.brand.toLowerCase()]"
                          /></h3>
                        </b-col>
                        <b-col
                          md="6"
                          class="text-right"
                        >
                          <b-button
                            variant="primary"
                            disabled
                            type="button"
                          >
                            Edit
                          </b-button>&nbsp;&nbsp;<b-button
                            variant="secondary"
                            type="button"
                          >
                            Delete
                          </b-button>
                        </b-col>
                        <b-col
                          cols="12"
                          class="pt-2"
                        >
                          **** **** **** {{ creditCard.last_four || '****' }}
                        </b-col>
                      </b-row>
                    </b-card>
                    <b-card
                      v-else
                      class="border"
                    >
                      <p>
                        No card saved yet!
                      </p>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col cols="12">
          <b-card>
            <h3>Billing Address</h3>
            <b-row>
              <b-col md="12">
                <div class="pt-1">
                  <b-form>
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label="Company Name"
                          label-for="cName"
                        >
                          <b-form-input
                            id="cName"
                            v-model="billingAddress.company"
                            placeholder="Company Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Billing Email"
                          label-for="bEmail"
                        >
                          <b-form-input
                            id="bEmail"
                            v-model="billingAddress.email"
                            placeholder="john.doe@email.com"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Tax ID"
                          label-for="taxId"
                        >
                          <b-form-input
                            id="taxId"
                            v-model="billingAddress.taxId"
                            placeholder="Tax ID"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="VAT Number"
                          label-for="vatNumber"
                        >
                          <b-form-input
                            id="vatNumber"
                            v-model="billingAddress.vat"
                            placeholder="VAT Number"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Mobile Number"
                          label-for="mobileNumber"
                        >
                          <b-form-input
                            id="mobileNumber"
                            v-model="billingAddress.mobile"
                            placeholder="647 758 2648"
                            :formatter="formatPhoneNumber"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Country"
                          label-for="country"
                        >
                          <b-form-select
                            id="country"
                            v-model="billingAddress.country"
                            :options="countries"
                            placeholder="USA"
                            @input="handleCountryChange"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group
                          label="Billing Address"
                          label-for="address"
                        >
                          <b-form-input
                            id="address"
                            v-model="billingAddress.address_1"
                            placeholder="Address"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Province/State"
                          label-for="state"
                        >
                          <b-form-select
                            id="country"
                            v-model="billingAddress.state"
                            :options="states"
                            placeholder="California"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Postal/Zip Code"
                          label-for="zipCode"
                        >
                          <b-form-input
                            id="zipCode"
                            v-model="billingAddress.postal_code"
                            placeholder="63854"
                            :formatter="formatPostalCode"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <p class="pt-1">
                      <b-button
                        variant="primary"
                        type="button"
                        @click="saveBillingAddress"
                      >
                        Save Changes
                      </b-button>&nbsp;<b-button
                        disabled
                        variant="secondary"
                        type="button"
                      >
                        Cancel
                      </b-button>
                    </p>
                  </b-form>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <section>
        <!-- table row -->
        <b-card>
          <b-row v-show="false">
            <b-col>
              No items to show.
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="2"
              sm="4"
            >
              <b-form-group
                class="mb-0"
                label="Per page"
                label-for="perPageSelect"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <!-- STATUS AND CAMPAIGN FILTER -->
            <!-- <b-col
              md="2"
              class="ml-auto"
            >
              <b-form inline>
                <b-form-select
                  id="statusSelect"
                  v-model="selectedStatus"
                  size="sm"
                  :options="statusOptions"
                />
              </b-form>
            </b-col> -->

            <!-- <b-col
              md="6"
              class="my-1"
            >
              <b-form-group class="mb-0">
                <label
                  class="d-block text-sm-left"
                  for="filterInput"
                >Filter</label>
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col> -->

            <!--
                :per-page="perPage"
                :current-page="currentPage"
            -->
            <b-col cols="12 mt-2">
              <b-table
                hover
                responsive
                :items="invoices"
                :fields="tableFields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template v-slot:head()="data">
                  <!-- check if header has an fa icon, display it -->
                  <span
                    v-if="data.field.icon"
                    class="mr-2"
                  ><i :class="data.field.icon" /></span>
                  <span
                    v-else
                    v-b-tooltip.hover
                    :title="data.field.tooltip"
                  >
                    {{ data.label }}
                  </span>
                </template>

                <template #cell(id)="data">
                  <span
                    class="text-info"
                    @click="viewInvoice(data.item.id)"
                  >#{{ data.item.id }}</span>
                </template>

                <template #cell(billed_at)="data">
                  <span :class="isOverdue(data.item.billed_at, data.item.paid)" />
                </template>

                <template #cell(total_cost)="data">
                  <span>${{ data.item.total_cost }}</span>
                </template>

                <template #cell(created_at)="data">
                  <span>{{ getReadableDateOnly(data.item.created_at) }}</span>
                </template>

                <template #cell(balance)="data">
                  <span
                    v-if="data.item.paid"
                    class="badge badge-success"
                  >Paid</span>
                  <span
                    v-else
                    class="text-secondary"
                  > -${{ data.item.total_cost }} </span>
                </template>

                <template #cell(actions)="data">
                  <div class="actions-bar">
                    <!-- <span class="d-inline-block mr-1">
                      <b-badge
                        v-b-popover.hover.top="'Send Notification'"
                        class="text-capitalize"
                        variant="light-info"
                      >
                        <i class="fa-regular fa-envelope" />
                      </b-badge>
                    </span> -->
                    <span class="d-inline-block mr-1">
                      <!-- v-b-popover.hover.top="'View Invoice'" -->
                      <b-badge
                        class="text-capitalize"
                        variant="light-primary"
                        @click="viewInvoice(data.item.id)"
                      >
                        <i class="fa-regular fa-eye" />
                      </b-badge>
                    </span>
                    <!-- <b-dropdown
                      variant="link"
                      toggle-class="p-0"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item href="#">
                        <i class="fa-regular fa-envelope text-info" />&nbsp; Send Notification
                      </b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item
                        href="#"
                        @click="viewInvoice(data.item.id)"
                      ><i class="fa-regular fa-eye text-primary" />&nbsp; View Details</b-dropdown-item>
                    </b-dropdown> -->
                  </div>
                </template>

              </b-table>
            </b-col>

            <b-col
              md="6"
            >
              <span class="text-secondary">Showing 1 to {{ invoices.length }} of {{ invoices.length }} entries</span>
            </b-col>
            <b-col
              md="6"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="0"
                :per-page="perPage"
                align="right"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>

        <b-modal
          id="invoiceModal"
          centered
          title="View Invoice"
          size="lg"
        >
          <b-row
            v-if="invoice"
            class=""
          >
            <b-col cols="12">
              <b-card>
                <h3>Current Plan</h3>
                <b-row>
                  <b-col md="6">
                    <p class="pt-1">
                      Current Plan is <span>{{ invoice.billing_level.name }}</span><br>
                      <small class="text-muted">A simple start for everyone</small>
                    </p>
                    <p class="pt-1">
                      Next billing date {{ getReadableDateOnly(invoice.billed_at) }}<br>
                      <small class="text-muted">Your invoice will be available in billing history</small>
                    </p>
                    <p class="pt-1">
                      $<span v-if="invoice.billing_level && invoice.billing_level.price_dollars">{{ invoice.billing_level.price_dollars || 0 }}</span> Per Month &nbsp; <span class="badge badge-primary">Popular</span><br>
                      <small class="text-muted">Standard plan for small to medium businesses</small>
                    </p>
                  </b-col>
                  <b-col md="6">
                    <b-alert
                      v-if="invoice
                        && invoice.line_items
                        && invoice.line_items.google_ads_spend
                        && invoice.line_items.google_ads_spend.cost > 500
                        && invoice.line_items.forms.count > 250"
                      show
                      variant="warning"
                      class="p-2"
                    >
                      <p class="font-weight-bold mb-1">
                        We need your attention!
                      </p>
                      <p>
                        Your plan requires an update.
                      </p>
                    </b-alert>
                    <b-row class="mt-1">
                      <b-col cols="6">
                        Ad spend to date
                      </b-col>
                      <b-col
                        cols="6"
                        class="text-right"
                      >
                        <!-- {{ currentDayOfMonth }} of {{ totalDaysInMonth }} Days -->
                      </b-col>
                      <b-col cols="12">
                        <b-progress
                          :max="planCount.adSpendMax"
                          height="1.2rem"
                          show-value
                        >
                          <b-progress-bar
                            :value="planCount.adSpend"
                            variant="success"
                          >
                            <span class="progressbar-label">${{ planCount.adSpend }}</span>
                          </b-progress-bar>
                        </b-progress>
                        <!-- <b-form-input
                          type="range"
                          min="1"
                          :max="totalDaysInMonth"
                          :value="currentDayOfMonth"
                          step="1"
                          class="form-control-range"
                        /> -->
                        <small class="text-muted">
                          ${{ planCount.adSpend }} ad spend to date
                        </small>
                      </b-col>
                    </b-row>
                    <b-row class="mt-1">
                      <b-col cols="6">
                        Form submissions to date
                      </b-col>
                      <b-col
                        cols="6"
                        class="text-right"
                      >
                        <!-- {{ currentDayOfMonth }} of {{ totalDaysInMonth }} Days -->
                      </b-col>
                      <b-col cols="12">
                        <b-form-input
                          type="range"
                          min="1"
                          :max="totalDaysInMonth"
                          :value="currentDayOfMonth"
                          step="1"
                          class="form-control-range"
                        />
                        <small class="text-muted">{{ invoice.line_items.forms.count }} of {{ invoice.forms_per_month }} form submissions to date</small>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-modal>

      </section>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BAlert,
  BBadge, BButton,
  BCard,
  BForm, BFormGroup, BFormSelect, BFormRadio, BFormInput, // BForm, BFormInput,
  BOverlay,
  BPagination, BTable,
  BSpinner,
  BNav, BNavItem,
  VBPopover,
  VBTooltip,
  BModal,
  BProgress, BProgressBar,
  // BDropdown, BDropdownDivider, BDropdownItem,
} from 'bootstrap-vue'
import { Country, State } from 'country-state-city'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    // BDropdownItem, BDropdownDivider, BDropdown,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormRadio,
    BFormInput,
    BOverlay,
    BPagination,
    BTable,
    BSpinner,
    BNav,
    BNavItem,
    BModal,
    BProgress,
    BProgressBar,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  filters: {

  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      loadingOverlay: true,
      errorMessage: null,

      perPage: 10,
      pageOptions: [5, 10, 15, 30, 45, 75],
      // totalRows: 1,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      tableFields: [
        { key: 'id', label: 'Id', sortable: true },
        {
          key: 'billed_at', label: '', icon: 'fa-solid fa-arrow-trend-up', sortable: true,
        },
        {
          key: 'agency_client_name', label: 'Client', tooltip: '',
        },
        {
          key: 'total_cost', label: 'Total', tooltip: '',
        },
        {
          key: 'created_at', label: 'Issued Date', tooltip: '',
        },
        {
          key: 'balance', label: 'Balance', tooltip: '',
        },
        {
          key: 'actions', label: 'Actions', tooltip: 'Invoice actions that can be taken',
        },
      ],
      statusColor: {
        verified: 'light-success',
        flagged: 'light-warning',
        new: 'light-info',
        rejected: 'light-danger',
        pending: 'light-warning',
        lost: 'light-danger',
        won: 'light-success',
      },
      types: {
        F: 'fa-solid fa-table-list',
        P: 'fa-solid fa-phone text-dark-green',
        O: 'fa-solid fa-border-none',
      },
      creditCard: {},
      tempCreditCard: {},
      paymentMethod: 'card',
      billingAddress: {
        country: 'United States',
      },
      countries: [],
      invoiceToDate: {
        /* id: null,
        agency_client_id: null,
        agency_client_name: null,
        created_at: null,
        billed_at: '',
        billing_period_start: '',
        billing_period_end: '',
        has_google_customer: false,
        has_credit_card_on_file: false,
        billing_level: {
          id: 1,
          name: '',
          price_pennies: 0,
          price_dollars: '0.00',
          forms_per_month: 0,
          local_phone_numbers_total: 0,
          local_talk_minutes_per_month: 0,
        },
        sub_total_cost: 0,
        gst_cost: 0,
        total_cost: 0,
        paid: false,
        line_items: {
          forms: {
            count: 20,
            included: 100,
            overage_count: 0,
            overage_rate: 500,
            cost: 0,
          },
          local_phone_numbers: {
            count: 0,
            included: 0,
            overage_count: 0,
            overage_rate: 400,
            cost: 0,
          },
          tollfree_phone_numbers: {
            count: 0,
            included: 0,
            overage_count: 0,
            overage_rate: 500,
            cost: 0,
          },
          local_talk_minutes: {
            count: 0,
            included: 0,
            overage_count: 0,
            overage_rate: 8,
            cost: 0,
          },
          tollfree_talk_minutes: {
            count: 0,
            included: 0,
            overage_count: 0,
            overage_rate: 8,
            cost: 0,
          },
          google_ads_spend: {
            cost: 0,
          },
        }, */
      },
      currentDate: new Date(),
      cardType: {
        visa: 'fa-cc-visa',
        mastercard: 'fa-cc-mastercard',
      },
      billingData: {
        /* id: 1,
        name: 'Free',
        price_pennies: 0,
        price_dollars: '0.00',
        forms_per_month: 100,
        local_phone_numbers_total: 0,
        local_talk_minutes_per_month: 0,
        next_billing_date: '2023-08-01', */
      },
      agencyClients: null,
      currentClient: null,
      invoices: [],
      invoice: null,
      selectedCountry: null,
      selectedState: null,
      states: [],
    }
  },
  computed: {
    currentDayOfMonth() {
      return this.currentDate.getDate()
    },
    totalDaysInMonth() {
      return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0).getDate()
    },
    planCount() {
      const planCount = {
        adSpend: 0,
        adSpendMax: 500,
        forms: 0,
        formsMax: 0,
        formsOverage: 0,
        formsLabel: 'success',
        formsLabelText: '',
        formsOverageLabelText: '',
      }
      if (this.invoiceToDate && this.invoiceToDate.line_items) {
        planCount.adSpend = this.invoiceToDate.line_items.google_ads_spend.cost || 0
        planCount.adSpendMax = Math.ceil(planCount.adSpend / 500) * 500
        planCount.forms = this.invoiceToDate.line_items.forms.count
        planCount.formsMax = this.invoiceToDate.line_items.forms.included
        planCount.formsOverage = this.invoiceToDate.line_items.forms.overage_count
      }
      if (planCount.forms > planCount.formsMax) {
        planCount.forms = this.invoiceToDate.line_items.forms.included
        planCount.formsMax = Math.ceil(this.invoiceToDate.line_items.forms.count / 100) * 100
        planCount.formsLabel = 'danger'
        planCount.formsLabelText = `Your plan limit is ${planCount.forms} form submissions.`
        planCount.formsOverageLabelText = `You have ${this.invoiceToDate.line_items.forms.count} form submissions (${planCount.formsOverage} over the limit).`
      }
      return planCount
    },
  },
  watch: {
  },
  created() {
    // load billing
    /* useJwt.getBillingLevels()
      .then(result => {
        console.log('getBillingLevels', result.data.data)
      })
    useJwt.getBillingPercentages()
      .then(result => {
        console.log('getBillingPercentages', result.data.data)
      })
    useJwt.getBillingOverages()
      .then(result => {
        console.log('getBillingOverages', result.data.data)
      }) */

    // get clients then load rest of page
    this.getClientsData()

    // let's format countries and data
    const countries = Country.getAllCountries()

    countries.forEach(c => {
      this.countries.push(
        {
          text: c.name,
          value: c.name,
          code: c.isoCode,
        },
      )
    })
  },
  methods: {
    getClientsData() {
      this.loadingOverlay = true
      useJwt.getAgencyClients()
        .then(result => {
          this.agencyClients = result.data.data
          const [client] = this.agencyClients
          this.currentClient = client
          this.billingAddress = {
            ...this.currentClient.billing_address,
            email: this.currentClient.name,
          }
          this.loadingOverlay = false

          console.log('currentClient id', this.currentClient.id)
          if (this.currentClient.id) {
            // if client exists, load rest of page
            this.getCreditCard()
            this.getInvoiceToDate()
            this.getNextBillingData()
            this.getAllInvoices()
          }
        })
        .catch(() => {
          this.loadingOverlay = false
        })
    },
    getCreditCard() {
      useJwt.getCreditCard(this.currentClient.id)
        .then(result => {
          this.creditCard = result.data.data
          this.loadingOverlay = false
        })
        .catch(error => {
          this.loadingOverlay = false
          console.log('getCreditCard ERROR', error.response)
        })
    },
    getInvoiceToDate() {
      // Get the invoice data to date
      this.loadingOverlay = true
      useJwt.getInvoiceToDate(this.currentClient.id)
        .then(result => {
          const [resultData] = result.data.data
          this.invoiceToDate = resultData
          console.log('getInvoiceToDate', this.invoiceToDate)
          this.loadingOverlay = false
        })
        .catch(error => {
          this.loadingOverlay = false
          console.log('getInvoiceToDate ERROR', error.response)
        })
    },
    setCreditCard() {
      this.tempCreditCard.year = this.tempCreditCard.exp.slice(3)
      this.tempCreditCard.month = this.tempCreditCard.exp.slice(0, 2)
      this.tempCreditCard.number = this.tempCreditCard.number.replace(/\s/g, '')
      useJwt.setCreditCard(this.currentClient.id, this.tempCreditCard)
        .then(() => {
          this.tempCreditCard = {}
          this.getCreditCard()
          this.showSuccessMessage('Credit card updated successfully')
        })
        .catch(error => {
          console.log('setCreditCard ERROR', error.response)
        })
    },
    getNextBillingData() {
      this.loadingOverlay = true
      useJwt.getNextBillingData(this.currentClient.id)
        .then(result => {
          const [billingData] = result.data.data
          this.billingData = billingData
          console.log('getNextBillingData', this.billingData)
          this.loadingOverlay = false
        })
        .catch(error => {
          this.loadingOverlay = false
          console.log('getNextBillingData ERROR', error.response)
        })
    },
    saveBillingAddress() {
      this.loadingOverlay = true
      useJwt.setBillingAddress(this.currentClient.id, this.billingAddress)
        .then(() => {
          this.showSuccessMessage('Billing address updated successfully')
          this.loadingOverlay = false
        })
        .catch(error => {
          this.loadingOverlay = false
          this.showErrorMessage(error.response.data.error[0])
        })
    },
    getAllInvoices() {
      this.loadingOverlay = true
      useJwt.getAllInvoices(this.currentClient.id)
        .then(result => {
          this.invoices = result.data.data
          this.loadingOverlay = false
        })
        .catch(error => {
          this.loadingOverlay = false
          console.log('getAllInvoices ERROR', error.response)
        })
    },
    viewInvoice(id) {
      this.getInvoice(id)
      this.$bvModal.show('invoiceModal')
    },
    getInvoice(id) {
      this.loadingOverlay = true
      useJwt.getInvoice(id)
        .then(result => {
          this.invoice = result.data.data
          this.loadingOverlay = false
        })
        .catch(error => {
          this.loadingOverlay = false
          console.log('getInvoice ERROR', error.response)
        })
    },

    /* MISCELLANEOUS */
    isOverdue(date, paid = false) {
      // eslint-disable-next-line no-else-return
      if (paid) {
        return 'fa-regular fa-circle-check text-secondary'
      }
      const today = new Date()
      const dueDate = new Date(date)
      if (dueDate < today) {
        return 'fa-solid fa-circle-exclamation text-danger'
      }
      return 'fa-solid fa-circle-half-stroke text-success'
    },
    getReadableDate(dateStr) {
      // parse unix date in readable format
      const date = new Date(dateStr)
      return `${date.toLocaleDateString([], { dateStyle: 'long' })} ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    },
    getReadableDateOnly(dateStr) {
      // convert yyyy-mm-dd date to Dec 09, 2021
      const date = new Date(dateStr)
      return date.toLocaleDateString([], { dateStyle: 'long' })
    },
    showErrorMessage(errorMessage) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: errorMessage,
        },
      },
      {
        timeout: false,
      })
    },
    showSuccessMessage(message) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'AlertCircleIcon',
          variant: 'success',
          text: message,
        },
      },
      {
        timeout: false,
      })
    },
    formatCreditCardNumber(value) {
      // Remove any non-numeric characters from the input
      let number = value.replace(/\D/g, '')

      // Add spaces every 4 digits
      number = number.replace(/(\d{4})(?=\d)/g, '$1 ')

      // Trim the input to a maximum of 19 characters (including spaces)
      return number.slice(0, 19)
    },
    formatExpiryDate(value) {
      // Remove any non-numeric characters from the input
      let expiry = value.replace(/\D/g, '')

      // Add a slash after the first 2 characters
      if (expiry.length > 2) {
        expiry = `${expiry.slice(0, 2)}/${expiry.slice(2)}`
      }

      // Trim the input to a maximum of 5 characters
      return expiry.slice(0, 5)
    },
    formatPhoneNumber(value) {
      // Remove any non-numeric characters from the input
      let phone = value.replace(/\D/g, '')

      // Format the phone number with spaces
      if (phone >= 3 && phone <= 6) {
        phone = `${phone.slice(0, 3)} ${phone.slice(3)}`
      } else if (phone > 6) {
        phone = `${phone.slice(0, 3)} ${phone.slice(3, 6)} ${phone.slice(6)}`
      }

      // Trim the input to a maximum of 12 characters
      return phone.slice(0, 12)
    },
    formatPostalCode(value) {
      // eslint-disable-next-line eqeqeq
      if (this.billingAddress.country == 'United States') {
        return this.formatUSPostalCode(value)
      }
      // Remove any non-alphanumeric characters from the input
      let postalCode = value.replace(/[^a-zA-Z0-9]/g, '')

      // Convert to uppercase
      postalCode = postalCode.toUpperCase()

      // Format the postal code with a space
      if (postalCode.length > 3) {
        postalCode = `${postalCode.slice(0, 3)} ${postalCode.slice(3)}`
      }

      // Trim the input to a maximum of 7 characters
      return postalCode.slice(0, 7)
    },
    handleCountryChange() {
      console.log('handleCountryChange', this.billingAddress.country)
      this.selectedState = null

      // eslint-disable-next-line arrow-body-style
      const country = Country.getAllCountries().find(c => {
        // eslint-disable-next-line eqeqeq
        return c.name == this.billingAddress.country
      })

      if (this.billingAddress.country) {
        this.states = State.getStatesOfCountry(country.isoCode).map(state => ({ text: state.name, value: state.name }))
      } else {
        this.states = []
      }
    },
    formatUSPostalCode(value) {
      // Remove any non-alphanumeric characters from the input
      let postalCode = value.replace(/\D/g, '')

      // Convert to uppercase
      postalCode = postalCode.toUpperCase()

      if (postalCode.length <= 5) {
        postalCode = postalCode.replace(/(\d{5})(\d{0,4})/, '$1-$2')
      } else {
        postalCode = postalCode.replace(/(\d{5})(\d{4})/, '$1-$2')
      }

      // Trim the input to a maximum of 10 characters
      return postalCode.slice(0, 10)
    },
  },
}
</script>

<style lang="scss">
.dropdown .btn-secondary{
  border: none;
  background-color: transparent !important;
}
.page-item{
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}
.page-item, .page-item.active .page-link{
  border-radius: 0.3rem;
}
.text-dark-green{
  color: #00CFE8;
}
body:not(.dark-layout) .text-secondary{
  color: #00000080 !important;
}
.actions-bar {
  min-width: 11rem;
}
</style>
